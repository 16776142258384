const LID = 2;
const BEHEERDER = 5;
const DEVELOPER = 6;
// const INSPECTEUR = 9;

export default ({store, redirect}) => {
  const roleIdList = [LID, BEHEERDER, DEVELOPER];
  if (store.state.auth.loggedIn && !roleIdList.includes(store.state.auth.user.role_id)) {
    return redirect('/');
  }
};
