<template>
  <section class="relative z-20">
    <button class="btn btn-secondary btn-square p-0 lg:mr-5" aria-label="Maak een item aan" @click="toggleMenu">
      <font-awesome-icon :icon="['far', 'plus']" size="lg"/>
    </button>

    <transition name="fade">
      <div v-if="showMenu" class="menu absolute flex flex-col items-end" @click="toggleMenu">
        <nuxt-link
          v-for="option in options"
          :key="option.id"
          :to="option.url"
          class="flex items-center mb-2 whitespace-no-wrap font-bold text-white no-underline"
        >
          <span class="item bg-primary-light rounded py-2 px-4 mr-2" v-text="option.name"/>
          <span class="item flex items-center justify-center bg-primary-light rounded-full h-10 w-10">
            <font-awesome-icon :icon="option.icon"/>
          </span>
        </nuxt-link>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AddMenu',
  data: () => ({
    showMenu: false,
    options: [
      {
        name: 'Project',
        url: '/admin/projecten/create',
        icon: ['far', 'clipboard'],
      },
      {
        name: 'Gebruiker',
        url: '/admin/gebruikers/create',
        icon: ['fas', 'user'],
      },
      {
        name: 'Opdrachtgever',
        url: '/admin/opdrachtgevers/create',
        icon: ['fas', 'briefcase'],
      },
      {
        name: 'Update',
        url: '/admin/projecten/update',
        icon: ['fas', 'briefcase'],
      },
    ],
  }),
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style scoped>
.menu a:hover .item {
  @apply bg-secondary text-primary transition duration-200;
}
.menu {
  top: -2rem;
  left: -5.5rem;
  background: theme('colors.primary.default');
  @screen lg {
    top: 3.5rem;
    right: 1.4rem;
    background: transparent;
  }
}
</style>
