<template>
  <section>
    <button v-if="!btn" class="btn btn-secondary py-2" @click="toggleModal">
      {{ download ? 'Download' : 'Filter' }}
    </button>
    <button v-else class="text-white text-sm font-bold underline" @click.stop="toggleModal">
      Download
    </button>
    <transition name="fade">
      <div
        v-if="open"
        :class="btn ? 'fixed' : 'absolute'"
        class="flex flex-col justify-center items-center top-0 left-0 bottom-0 right-0 bg-primary-through download-modal"
        tabindex="0"
        style="z-index: 999999;"
        @keydown.esc="toggleModal"
        @click="toggleModal"
      >
        <section class="bg-white w-full md:w-2/3 lg:w-3/5 xl:w-1/2 xxl:w-2/5" @click.stop>
          <h2 class="bg-secondary text-center text-title font-black text-primary py-6">
            {{ download ? 'Download project rapport' : 'Filter' }}
          </h2>
          <div class="px-16 pt-8 pb-12">
            <h3 v-if="download" class="font-normal pb-6">{{ selection ? 'Selecteer updates' : 'Selecteer periode' }}</h3>
            <section v-if="download && !selection" class="grid md:grid-cols-2 gap-6 mb-4">
              <div class="relative p-3 border border-primary">
                <h4 class="absolute -top-3 left-2 bg-white block mb-1 font-bold px-1">
                  Van datum
                </h4>
                <date-picker
                  v-model="fromDate"
                  placeholder="Kies datum"
                  input-class="text-sm"
                  class="w-input"
                />
              </div>
              <div class="relative p-3 border border-primary">
                <h4 class="absolute -top-3 left-2 bg-white block mb-1 font-bold px-1">
                  Tot datum
                </h4>
                <date-picker
                  v-model="untilDate"
                  placeholder="Kies datum"
                  input-class="text-sm"
                  class="w-input"
                />
              </div>
            </section>
            <div v-if="selection" class="mb-4">
              <div class="flex flex-wrap -mx-px overflow-hidden">
                <div
                  v-for="projectUpdate in projectUpdates"
                  :key="projectUpdate.id"
                  class="my-px px-px sm:w-1/2 sm:w-full md:w-full lg:w-1/2 xl:w-1/2"
                >
                  <template>
                    <div>
                      <input
                        :id="'update-' + projectUpdate.id"
                        type="checkbox"
                        :name="'update-' + projectUpdate.id"
                        class="option option-square update-checkbox"
                        :onload="projectUpdate.checked = true"
                        checked
                        @change="projectUpdate.checked = $event.target.checked"
                      >
                      <label class="text-primary" :for="'update-' + projectUpdate.id">{{ projectUpdate.title }}</label>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <label
              v-if="download"
              class="text-primary underline font-black"
              @click="reverseSelection"
            >
              {{ selection ? 'Of selecteer periode' : 'Of selecteer updates' }}
            </label>
            <template v-if="!download">
              <section class="flex flex-col items-center pt-8">
                <div v-for="field in selects" :key="field.label" class="w-full lg:w-1/2 relative mb-8">
                  <label
                    :for="field.label"
                    class="absolute -top-3 left-2 bg-white text-primary block mb-1 font-bold px-1"
                    v-text="field.label"
                  />
                  <select
                    :id="field.label"
                    v-model="filters.sort"
                    :name="field.label"
                    class="appearance-none w-full block border border-primary bg-transparent px-3 pt-4 pb-2"
                    required
                  >
                    <option
                      v-for="option in field.options"
                      :key="option.text"
                      :value="option.value"
                      v-text="option.text"
                    />
                  </select>
                  <div class="pointer-events-none absolute bg-white border-l-0 border border-primary inset-y-0 top-0 right-0 flex items-center px-3 pt-4 pb-2">
                    <font-awesome-icon
                      :icon="['far', 'sort']"
                      aria-hidden="true"
                      class="-mt-1"
                      size="sm"
                    />
                  </div>
                </div>
                <div>
                  <input
                    id="option-filter"
                    v-model="filters.published"
                    type="checkbox"
                    name="option"
                    class="option option-square"
                  >
                  <label for="option-filter" :class="{'text-primary': $route.name !== 'index'}">Toon alleen actieve
                    updates</label>
                </div>
              </section>
            </template>
            <div :class="!download ? 'pt-12' : 'pt-6'" class="flex flex-row items-center justify-around">
              <button v-if="download" id="download-btn" ref="downloadBtn" class="btn btn-secondary px-6 py-2" @click="getReport(false)">
                Download
              </button>
              <button v-if="!download" class="btn btn-secondary px-6 py-2" @click="filterBy">
                Opslaan
              </button>
              <svg id="rapport-loader" ref="rapportLoader" class="animate-spin h-7 w-7 text-white hidden absolute" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#3C4F28" stroke-width="4"/>
                <path class="opacity-75" fill="#3C4F28" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
              </svg>
              <button v-if="!download" class="font-black underline mt-3" @click="resetFilters">
                Reset filters
              </button>
              <button class="btn btn-secondary px-6 py-2" @click="toggleModal">
                Annuleren
              </button>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import projectPdf from '~/graphql/queries/projectPdf';

export default {
  name: 'ModalBtn',
  components: {
    DatePicker,
  },
  props: {
    download: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: false,
    },
    projectUpdates: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selection: false,
    open: false,
    fromDate: null,
    untilDate: null,
    filters: {
      sort: 'desc',
      published: false,
    },
    selects: [
      {
        label: 'Sorteer op',
        options: [
          {text: 'Datum oplopend', value: 'asc'},
          {text: 'Datum aflopened', value: 'desc'},
        ],
      },
    ],
  }),
  methods: {
    toggleModal() {
      this.open = !this.open;
    },
    filterBy() {
      this.$emit('filters', this.filters);
      this.toggleModal();
    },
    resetFilters() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.filterBy();
      this.toggleModal();
    },
    reverseSelection() {
      this.selection = !this.selection;
    },
    getReport(repeat = false) {
      if (!this.selection && this.fromDate == null && this.untilDate == null) {
        this.$toast.error('Datum niet geselecteerd');
        return;
      }

      this.$refs.downloadBtn.setAttribute('disabled', 'true');
      this.$refs.rapportLoader.style.display = 'block';

      this.updates = [];

      for (let i = 0; i < this.projectUpdates.length; i++) {
        this.update = this.projectUpdates[i];
        // Check if user is selecting the updates manually or using the datepickers
        if (this.selection) {
          // Check if the update checkbox is checked
          if (this.update.checked) {
            this.updates.push(this.update.id);
          }
        } else if (moment(this.update.created_at).isAfter(this.fromDate) && moment(this.update.created_at).isBefore(this.untilDate)) {
          this.updates.push(this.update.id);
        }
      }
      if (this.updates.length <= 0) {
        this.toggleModal();
        return this.$toast.error('PDF Kan niet gegenereerd worden zonder updates');
      }

      const mapWrap = document.getElementById('map-wrap');
      if (!repeat) {
        mapWrap.setAttribute('image', '');
        this.$emit('render-image');
      }
      // Wait until the render is done
      const image = mapWrap.getAttribute('image');
      if (!image) {
        return setTimeout(this.getReport, 500, true);
      }

      this.$apollo.query({
        query: projectPdf,
        variables: {
          id: this.$route.params.id,
          updates: this.updates,
          image: image,
        },
      }).then((response) => {
        this.linkSource = 'data:application/pdf;base64,' + response.data.projectPdf;
        this.downloadLink = document.createElement('a');

        this.datetime = new Date();
        this.fileName = 'Project_' + this.$route.params.id + '_' + moment(this.datetime).format('DD-MM-YYYY_h-mm-ss') + '.pdf';

        this.downloadLink.href = this.linkSource;
        this.downloadLink.download = this.fileName;
        this.downloadLink.click();
        this.toggleModal();
      });
    },
  },
};
</script>

<style  scoped>
.w-input {
  width: 106% !important;
}

.download-modal {
  z-index: 1001 !important;
}

.mx-datepicker-main {
  z-index: 1002 !important;
}
</style>
