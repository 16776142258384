import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _925b02a6 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _54378a1c = () => interopDefault(import('../pages/admin/gebruikers/index.vue' /* webpackChunkName: "pages/admin/gebruikers/index" */))
const _372d6440 = () => interopDefault(import('../pages/admin/opdrachtgevers/index.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/index" */))
const _5b9e80ba = () => interopDefault(import('../pages/admin/projecten/index.vue' /* webpackChunkName: "pages/admin/projecten/index" */))
const _0e576e0c = () => interopDefault(import('../pages/admin/gebruikers/create.vue' /* webpackChunkName: "pages/admin/gebruikers/create" */))
const _53992304 = () => interopDefault(import('../pages/admin/opdrachtgevers/create.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/create" */))
const _c8c9010a = () => interopDefault(import('../pages/admin/projecten/create.vue' /* webpackChunkName: "pages/admin/projecten/create" */))
const _385b68c8 = () => interopDefault(import('../pages/admin/projecten/update.vue' /* webpackChunkName: "pages/admin/projecten/update" */))
const _36c851da = () => interopDefault(import('../pages/admin/gebruikers/_id.vue' /* webpackChunkName: "pages/admin/gebruikers/_id" */))
const _897112b0 = () => interopDefault(import('../pages/admin/opdrachtgevers/_id.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/_id" */))
const _3d8b510e = () => interopDefault(import('../pages/admin/projecten/_id/index.vue' /* webpackChunkName: "pages/admin/projecten/_id/index" */))
const _28c64980 = () => interopDefault(import('../pages/admin/projecten/_id/update/index.vue' /* webpackChunkName: "pages/admin/projecten/_id/update/index" */))
const _6b3ae39e = () => interopDefault(import('../pages/admin/projecten/_id/update/_updateid.vue' /* webpackChunkName: "pages/admin/projecten/_id/update/_updateid" */))
const _36df6746 = () => interopDefault(import('../pages/inloggen/index.vue' /* webpackChunkName: "pages/inloggen/index" */))
const _683fb45e = () => interopDefault(import('../pages/instellingen.vue' /* webpackChunkName: "pages/instellingen" */))
const _7e1c4ae3 = () => interopDefault(import('../pages/projecten/index.vue' /* webpackChunkName: "pages/projecten/index" */))
const _058a6601 = () => interopDefault(import('../pages/inloggen/callback.vue' /* webpackChunkName: "pages/inloggen/callback" */))
const _64dc1510 = () => interopDefault(import('../pages/inloggen/over.vue' /* webpackChunkName: "pages/inloggen/over" */))
const _74473dd9 = () => interopDefault(import('../pages/inloggen/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/inloggen/wachtwoord-vergeten" */))
const _9614abea = () => interopDefault(import('../pages/projecten/_id.vue' /* webpackChunkName: "pages/projecten/_id" */))
const _70e100e8 = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _f539aaa0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _925b02a6,
    pathToRegexpOptions: {"strict":true},
    name: "admin",
    children: [{
      path: "gebruikers",
      component: _54378a1c,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers"
    }, {
      path: "opdrachtgevers",
      component: _372d6440,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers"
    }, {
      path: "projecten",
      component: _5b9e80ba,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten"
    }, {
      path: "gebruikers/create",
      component: _0e576e0c,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers-create"
    }, {
      path: "opdrachtgevers/create",
      component: _53992304,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers-create"
    }, {
      path: "projecten/create",
      component: _c8c9010a,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-create"
    }, {
      path: "projecten/update",
      component: _385b68c8,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-update"
    }, {
      path: "gebruikers/:id",
      component: _36c851da,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers-id"
    }, {
      path: "opdrachtgevers/:id",
      component: _897112b0,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers-id"
    }, {
      path: "projecten/:id",
      component: _3d8b510e,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id"
    }, {
      path: "projecten/:id/update",
      component: _28c64980,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id-update"
    }, {
      path: "projecten/:id/update/:updateid",
      component: _6b3ae39e,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id-update-updateid"
    }]
  }, {
    path: "/inloggen",
    component: _36df6746,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen"
  }, {
    path: "/instellingen",
    component: _683fb45e,
    pathToRegexpOptions: {"strict":true},
    name: "instellingen"
  }, {
    path: "/projecten",
    component: _7e1c4ae3,
    pathToRegexpOptions: {"strict":true},
    name: "projecten"
  }, {
    path: "/inloggen/callback",
    component: _058a6601,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-callback"
  }, {
    path: "/inloggen/over",
    component: _64dc1510,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-over"
  }, {
    path: "/inloggen/wachtwoord-vergeten",
    component: _74473dd9,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-wachtwoord-vergeten"
  }, {
    path: "/projecten/:id",
    component: _9614abea,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _70e100e8,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _f539aaa0,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
