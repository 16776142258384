import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faBars,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faEnvelope,
  faInfoCircle,
  faLink,
  faPhone,
  faPlus,
  faSort,
  faTimes,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faBriefcase,
  faMapMarkerEdit,
  // faSort as faSortC,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';

config.autoAddCss = false;

library.add(
  faBars,
  faBriefcase,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faEnvelope,
  faInfoCircle,
  faLink,
  faMapMarkerEdit,
  faPhone,
  faPlus,
  faSort,
  // faSort as faSortC,
  faTimes,
  faUser,
  faPencil,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
