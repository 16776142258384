<template>
  <section class="flex bg-secondary py-4 md:py-8 px-4 md:px-8 lg:px-16">
    <h1 class="text-primary" v-text="title"/>
  </section>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
