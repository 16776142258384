const LID = 2;
const BEHEERDER = 5;
const DEVELOPER = 6;
const INSPECTEUR = 9;
const KIJKER = 10;

export default {
  data() {
    return {
      adminGroup: [
        BEHEERDER,
        DEVELOPER,
      ],
      userGroup: [
        LID,
        BEHEERDER,
        DEVELOPER,
      ],
      customerGroup: [
        INSPECTEUR,
      ],
    };
  },
  computed: {
    userRoles() {
      const userRoles = [
        {
          id: KIJKER,
          title: 'Kijker',
        },
        {
          id: INSPECTEUR,
          title: 'Contactpersoon Opdrachtgever',
        },
        {
          id: LID,
          title: 'Ecoloog',
        },
      ];
      if (this.belongsToAdminGroup) {
        userRoles.push({
          id: BEHEERDER,
          title: 'Beheerder',
        });
      }
      return userRoles;
    },
    belongsToAdminGroup() {
      return this.belongsToGroup('admin');
    },
    belongsToUserGroup() {
      return this.belongsToGroup('user');
    },
    belongsToCustomerGroup() {
      return this.belongsToGroup('customer');
    },
  },
  methods: {
    belongsToGroup(group, roleId = null) {
      if (!this.$store.state.auth.loggedIn) {
        return false;
      }

      roleId = roleId ? parseInt(roleId) : this.$store.state.auth.user.role_id;

      switch (group) {
        case 'admin':
          return this.adminGroup.includes(roleId);
        case 'user':
          return this.userGroup.includes(roleId);
        case 'customer':
          return this.customerGroup.includes(roleId);
        default:
          throw Error(`No proper group specified! Use one of the following admin, user, customer.`);
      }
    },
    isAdmin() {
      return this.belongsToGroup('admin', this.$auth.user.role_id);
    },
  },
};
