const LID = 2;
const BEHEERDER = 5;
const DEVELOPER = 6;
// const INSPECTEUR = 9;

export default ({store, redirect}) => {
  if (store.state.auth.loggedIn) {
    switch (store.state.auth.user.role_id) {
      case DEVELOPER:
      case BEHEERDER:
      case LID:
        return redirect('/admin/projecten');
    }
  } else return redirect('/inloggen');
};
