const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['clearValidationErrors'] = require('../middleware/clearValidationErrors.js')
middleware['clearValidationErrors'] = middleware['clearValidationErrors'].default || middleware['clearValidationErrors']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['pages'] = require('../middleware/pages.js')
middleware['pages'] = middleware['pages'].default || middleware['pages']

middleware['roleRouting'] = require('../middleware/roleRouting.js')
middleware['roleRouting'] = middleware['roleRouting'].default || middleware['roleRouting']

export default middleware
