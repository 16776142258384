<template>
  <section class="flex justify-between items-center bg-primary h-16 md:h-24 mx-4 md:mx-8 lg:mx-16">
    <nuxt-link :to="{name: 'index'}">
      <img :src="require('~/assets/img/logo.svg')" alt="" aria-hidden="true" class="w-3/4 lg:w-full">
    </nuxt-link>
    <!-- DESKTOP -->
    <nav class="hidden lg:block flex-grow px-8">
      <ul v-if="belongsToUserGroup" class="flex">
        <li
          v-for="item in menuItems.admin"
          :key="item.title"
          class="px-4"
        >
          <nuxt-link
            :to="item.url"
            :class="{'shadow-link': $route.path === item.url}"
            class="text-xl font-semibold text-white no-underline px-3 text-center hover:shadow-link"
            v-text="item.title"
          />
        </li>
      </ul>
      <ul v-else class="flex">
        <li
          v-for="item in menuItems.user"
          :key="item.title"
          class="px-4"
        >
          <nuxt-link
            :to="item.url"
            :class="{'shadow-link': $route.path === item.url}"
            class="text-xl font-semibold text-white no-underline px-3 text-center hover:shadow-link"
            v-text="item.title"
          />
        </li>
      </ul>
    </nav>
    <section v-if="belongsToUserGroup" class="hidden lg:block">
      <add-menu/>
    </section>
    <button class="hidden lg:block btn btn-secondary text-xl" @click="$auth.logout()">
      Uitloggen
    </button>
    <button class="lg:hidden" @click="toggle">
      <font-awesome-icon :icon="['far', 'bars']" size="2x" color="white"/>
    </button>
    <!-- MOBILE -->
    <transition name="fade">
      <mobile-drawer v-if="open" :items="menuItems" @navigate="open = false"/>
    </transition>
  </section>
</template>

<script>
import AddMenu from '~/layouts/partials/AddMenu';
import MobileDrawer from '~/layouts/partials/MobileDrawer';
// Mixins
import userRoles from '~/plugins/mixins/userRoles';

export default {
  components: {
    AddMenu,
    MobileDrawer,
  },
  mixins: [userRoles],
  data() {
    return {
      open: false,
      menuItems: {
        admin: [
          {
            title: 'Projecten',
            url: '/admin/projecten',
          },
          {
            title: 'Gebruikers',
            url: '/admin/gebruikers',
          },
          {
            title: 'Opdrachtgevers',
            url: '/admin/opdrachtgevers',
          },
          {
            title: 'Instellingen',
            url: '/instellingen',
          },
        ],
        user: [
          {
            title: 'Dashboard',
            url: '/',
          },
          {
            title: 'Projecten',
            url: '/projecten',
          },
          {
            title: 'Instellingen',
            url: '/instellingen',
          },
        ],
      },
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>
