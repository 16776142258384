<template>
  <div>
    <template v-if="error.statusCode === 410 || error.statusCode === 404">
      <section class="bg-white">
        <title-bar :title="page.title"/>

        <div class="relative h-dashboard pt-8 pb-12 px-4 md:px-8 lg:px-16">
          <!--eslint-disable-next-line-->
          <div
            class="text-primary mb-4"
            v-html="page.template ? page.template.tekst : ''"
          />

          <nuxt-link :to="{name: 'index'}">
            Terug naar de homepage
          </nuxt-link>
        </div>
      </section>
    </template>

    <section v-else class="bg-white">
      <title-bar title="Er is een fout opgetreden"/>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import TitleBar from '~/layouts/partials/TitleBar';

export default {
  components: {
    TitleBar,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>

<style scoped>
>>> p {
  color: #000;
}
</style>
