<template>
  <section
    class="grid md:grid-cols-5 xxl:grid-cols-6 h-screen bg-center bg-cover login-bg"
    :style="`background-image: url(${require('~/assets/img/dummy.jpg')});`"
  >
    <div
      :class="{'login': $route.name !== 'inloggen-over'}"
      class="leaf relative sm:col-start-2 md:col-end-5 lg:col-end-4 bg-primary sm:h-75v lg:h-85v xxl:h-75v pt-16 sm:pt-32 lg:pt-12 3xl:pt-32 overflow-hidden z-10 login-div"
    >
      <transition name="fade">
        <main>
          <nuxt/>
        </main>
      </transition>
    </div>
  </section>
</template>
<script>
export default {
  auth: false,
};
</script>
<style scoped>
.login {
  clip-path: polygon(0 0, 100% 0, 100% 20%, 100% calc(100% - 90px), calc(100% - 90px) 100%, 0 100%, 0% 80%, 0% 20%);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-width: 90px 90px 0 0;
    border-style: solid;
    border-color: theme('colors.primary.light') transparent;
    box-shadow: -4px -4px 6px 0 rgba(0, 0, 0, 0.07);
    display: block;
    width: 0;
  }
}

.leaf {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(~assets/img/leaf-background.png) no-repeat top;
    transform: rotate(180deg) translateY(50%) scale(1.3);
    opacity: 0.18;
    z-index: -1;
  }
}

.login-bg{
  min-height: 575px;
}

.login-div{
  min-height: 575px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
