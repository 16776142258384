<template>
  <section class="mobile-nav flex flex-col justify-center sm:justify-start items-center text-center overflow-auto absolute left-0 right-0 bottom-0 bg-primary px-4 md:px-8 sm:pt-24 sm-ls:pt-0 z-30">
    <ul v-if="items.admin" class="flex flex-col">
      <li
        v-for="item in items.admin"
        :key="item.title"
        class="p-4"
        @click="$emit('navigate')"
      >
        <nuxt-link
          :to="item.url"
          :class="{'shadow-link': $route.path === item.url}"
          class="text-xl font-semibold text-white no-underline px-3 text-center hover:shadow-link"
          v-text="item.title"
        />
      </li>
    </ul>
    <ul v-else class="flex flex-col">
      <li
        v-for="item in items.user"
        :key="item.title"
        class="p-4"
        @click="$emit('navigate')"
      >
        <nuxt-link
          :to="item.url"
          :class="{'shadow-link': $route.path === item.url}"
          class="text-xl font-semibold text-white no-underline px-3 text-center hover:shadow-link"
          v-text="item.title"
        />
      </li>
    </ul>
    <section v-if="items.admin">
      <add-menu class="mt-12 md:mt-24 sm-ls:mt-12 mb-8"/>
    </section>
    <button :class="{'mt-24 sm-ls:mt-12': items.user}" class="btn btn-secondary text-xl" @click="$auth.logout()">
      Uitloggen
    </button>
  </section>
</template>

<script>
import AddMenu from '~/layouts/partials/AddMenu';

export default {
  components: {
    AddMenu,
  },
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style  scoped>
.mobile-nav {
  height: calc(100vh - 64px);
  @screen md {
    height: calc(100vh - 96px);
  }
}
</style>
