// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/img/leaf-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-71f6e63a]{-webkit-clip-path:polygon(0 0,100% 0,100% 20%,100% calc(100% - 90px),calc(100% - 90px) 100%,0 100%,0 80%,0 20%);clip-path:polygon(0 0,100% 0,100% 20%,100% calc(100% - 90px),calc(100% - 90px) 100%,0 100%,0 80%,0 20%)}.login[data-v-71f6e63a]:before{border-color:#6b7c59 transparent;border-style:solid;border-width:90px 90px 0 0;box-shadow:-4px -4px 6px 0 rgba(0,0,0,.07);display:block;width:0}.leaf[data-v-71f6e63a]:after,.login[data-v-71f6e63a]:before{content:\"\";position:absolute;bottom:0;right:0}.leaf[data-v-71f6e63a]:after{top:0;left:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;transform:rotate(180deg) translateY(50%) scale(1.3);opacity:.18;z-index:-1}.login-bg[data-v-71f6e63a],.login-div[data-v-71f6e63a]{min-height:575px}.fade-enter-active[data-v-71f6e63a],.fade-leave-active[data-v-71f6e63a]{transition:opacity 2s}.fade-enter[data-v-71f6e63a],.fade-leave-to[data-v-71f6e63a]{opacity:0}", ""]);
// Exports
module.exports = exports;
