export const state = () => ({
  release: process.env.RELEASE,
  page: {},
  settings: {},
});

export const actions = {
  /* async nuxtServerInit({commit, dispatch}) {
    try {
      const [settings, footerMenu, mainMenu, locations, news] = await Promise.all([
        this.$axios.get('/settings'),
      ]);

      commit('SET_SETTINGS', settings.data);
    } catch (e) {
      console.log(e);
    }
  }, */
  async getPage({commit}, slug = '') {
    const {data} = await this.$axios.get(`/page?segments=${slug}`);

    commit('SET_PAGE', data.data);
  },
};

export const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
};
