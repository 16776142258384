<template>
  <div class="flex flex-col min-h-screen bg-primary">
    <header>
      <app-bar/>
    </header>
    <main class="flex-grow">
      <transition name="fade">
        <nuxt/>
      </transition>
    </main>
  </div>
</template>

<script>
import AppBar from '~/layouts/partials/AppBar';

export default {
  components: {
    AppBar,
  },
};
</script>
