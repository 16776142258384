import {createUploadLink} from 'apollo-upload-client';

export default function (app) {
  const uploadLink = createUploadLink({
    uri: process.env.API_URL.replace('/api', '') + '/graphql',
    fetch: (uri, options) => {
      app.store.dispatch('validation/clearErrors');
      // Add authorization token
      const token = app.$cookies.get('auth._token.local');
      if (token) {
        options.headers.authorization = token;
      }
      return fetch(uri, options);
    },
  });

  return {
    defaultHttpLink: false,
    inMemoryCacheOptions: {
      addTypename: false,
    },
    link: uploadLink,
  };
}
