/**
 * Legenda:
 *
 * WSG84 ( World Geodetic System 1984 ) - Is a standard for use in cartography, geodesy, and
 * satellite navigation including GPS.
 * RD-coördinaten ( Rijksdriehoekscoördinaten ) - Zijn de coördinaten in het geodetisch
 * coördinatensysteem dat voor Europees Nederland op nationaal niveau wordt gebruikt als
 * grondslag voor geografische aanduidingen en bestanden.
 *
 * CRS stands for: coordinate reference system
 */
import L from 'leaflet';
import proj4 from 'proj4';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

export default ({app}, inject) => {
  // Rijksdriehoek Projectie
  proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 ' +
    '+lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 ' +
    '+ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 ' +
    '+units=m +no_defs');
  inject('proj4', proj4);
  const projection = {
    project: (coordinates) => {
      const point = proj4('EPSG:28992').forward([coordinates.lng, coordinates.lat]);
      return new L.Point(point[0], point[1]);
    },
    unproject: (point) => {
      const coordinates = proj4('EPSG:28992').inverse([point.x, point.y]);
      return L.latLng(coordinates[1], coordinates[0]);
    },
    bounds: L.bounds([482.06, 308914.15], [275902.39, 636381.86]),
    proj4def: proj4('EPSG:28992'),
  };
  inject('projection', projection);
};
